@import "_reset.scss";
@import "_variables";

@import "_skeletons";
@import "_formItem";
@import "_toggleSwitch";
@import "_progressbar";
@import "_breadcrumb";
@import "_tabNav";
@import "_splashscreen";
@import "_drawer";
@import "_addToHomeScreen";
@import "_offlineBanner";
@import "_updatePWASnackbar";

html,
body {
  min-height: 100vh;
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1;
  font-size: 1em;
}

h1 {
  font-size: 2em;
  margin-bottom: 1.2em;
}

h2 {
  font-size: 1.5em;
  margin-bottom: 0.75em;
}

button:not(:disabled) {
  cursor: pointer;
}

a {
  text-decoration: none;
  font-size: inherit;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  color: inherit;

  &:visited,
  &:hover,
  &:focus {
    color: inherit;
  }
}

#root {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.sub-header,
.toolbar {
  flex: 0 0 auto;
}

.sub-header {
  .meta-data {
    font-size: 0.75em;
    font-weight: 200;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .meta-value {
      margin-left: 0.35em;
      font-weight: 500;
    }
  }
  margin-bottom: 1em;
}

.content {
  flex: 1 1 auto;
  position: relative;
  overflow-y: auto;
}

.toolbar {
  padding-top: 10px;
  padding-bottom: 10px;
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.16);
  padding-left: 2rem;
  padding-right: 2rem;
  font-size: 0.75em;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-weight: 200;

  .toolbar-meta {
    font-weight: 200;
    display: flex;

    .meta-group {
      margin-left: 0.8em;
      display: flex;

      .meta-icon {
        width: 0.875em;
        height: 0.875em;
        border-radius: 4px;
        margin-right: 0.33em;
      }
      .meta-value {
        margin-left: 0.35em;
        font-weight: 500;
      }
    }
  }
}

.lr-pdd {
  padding-left: $mobile-content-margin;
  padding-right: $mobile-content-margin;
}

@media only screen and (min-width: $medium-device-breakpoint) {
  .lr-pdd {
    padding-left: $desktop-content-margin;
    padding-right: $desktop-content-margin;
  }
}

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  .content-wrapper {
    flex: 1 1 auto;
    position: relative;
    overflow-y: auto;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    display: flex;
  }
}

/* == LOGO == */
.logo {
  max-width: 250px;
  width: 40vh;
  height: auto;
  padding: 20px;
  margin: 10vh auto;
}
.logoImg {
  max-width: 150px;
  width: 6vh;
  height: auto;
}
.logoText {
  max-width: 250px;
  width: 40vh;
  height: auto;
}

.logo {
  text-align: center;
}

.logo h1 span {
  font-weight: 400;
}

.form-msg {
  display: flex;
  flex-direction: row;
  padding: 0.5em;
  margin-top: 0.5em;
  margin-bottom: 1.5em;
  font-size: 0.85em;
  border-left: 2px solid;
  border-radius: 2px;
  align-items: center;
  justify-content: flex-start;

  div {
    margin-left: 0.5em;
  }
}

/* == BUTTON == */
.btn {
  display: inline-block;
  text-align: center;
  text-decoration: none;
  font-weight: 300;
  font-size: inherit;
  line-height: 1.2;
  vertical-align: middle;
  padding: 0.5rem 1.5rem;
  border: 1px solid;
  text-transform: uppercase;
  border-radius: 4px 0px 0px 4px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &.secondary-btn {
    border: 0;
    background: transparent;
  }
}

.btn:disabled,
.btn.disabled {
  opacity: 0.65;
}

.btn-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: stretch;
  align-content: stretch;
  & .btn {
    margin-right: -$mobile-content-margin;
    min-width: 60%;
  }
}

/* === App style === */
.auth-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  flex: 1 1 auto;
  position: relative;
  overflow-y: auto;

  .auth-content {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 22em - 2 * $mobile-content-margin;
    .btn-wrapper {
      margin-top: 1em;
    }
    a {
      margin-top: 4em;
      text-align: right;
      margin-bottom: 3em;
      font-weight: 200;
      font-size: 0.8em;
    }
  }
}

@media (min-device-width: $small-device-breakpoint) {
  .auth-content {
    max-width: 22em - 2 * $mobile-content-margin;
  }
}

.stat-imp-content {
  margin-bottom: 1.2em;
  font-weight: 200;
  cursor: pointer;

  .stat-imp-info {
    font-size: 0.875em;
    margin-bottom: 0.4em;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &:first-child {
      font-weight: 400;
    }
  }

  .stat-imp-meta {
    font-size: 0.75em;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 0.4em;

    .imp-type {
      font-size: 0.875rem;
    }

    .stat-imp-det {
      display: flex;
      flex-direction: row;

      .stat-imp-det-item {
        & > span {
          font-weight: 500;
          &::before {
            content: " ";
          }
        }
        &:not(:first-child)::before {
          content: "/ ";
        }
        &:not(:last-child)::after {
          content: " ";
          white-space: pre;
        }
      }
    }
  }
}

/* ==== Main Nav ==== */
header {
  flex: 0 0 auto;
}
.main-nav {
  height: 52px;
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  position: relative;

  .nav-action {
    position: absolute;
    left: 1em;

    .nav-btn {
      text-decoration: none;
      font-size: inherit;
      line-height: 1.2;
      vertical-align: middle;
      background: transparent;
      border: 0px;
      outline: none;
    }
  }

  .brand {
    //margin: 0 auto;
    height: 32px;
    width: auto;
    position: absolute;
    right: 1em;
  }
}

.root-wrapper {
  display: flex;
  flex-direction: row;
  height: 100%;
}

/* ==== INDICATOR ==== */
.indicator-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid;
  padding-bottom: 0.5em;
  margin-bottom: 1.2em;

  .indicator-lbl {
    font-size: 0.875em;
    width: 55px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }

  .indicator-pbars-wrapper {
    display: flex;
    flex-grow: 1;
    flex-direction: column;

    .pbar:not(:first-child) {
      margin-top: 0.5em;
    }
  }
}

.main-menu-container {
  display: flex;
  flex-direction: column;
  height: 100%;

  & .main-menu-header {
    flex: 0 0 auto;
    height: $main-menu-header-height;
    max-height: 20%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: $foreground-header-color;

    .avatar {
      height: 46px;
      width: 46px;
      font-size: 46px;
      border-radius: 23px;
      overflow: hidden;

      & i {
        margin: 0;
        &::before {
          margin: 0;
        }
      }
    }
  }

  & .main-menu-content {
    flex: 1 1 auto;
    position: relative;
    overflow-y: auto;
    padding-top: 1em;
    padding-bottom: 1em;
  }
}

@media only screen and (min-width: $medium-device-breakpoint) {
  .main-menu-container {
    border-right: 1px solid;
  }
}

.nav {
  list-style-type: none;

  & .nav-item,
  & .nav-separator-header {
    padding-top: 0.8em;
    padding-bottom: 0.8em;
    padding-left: 1em;
    cursor: pointer;
  }

  & .nav-item {
    .icon-menu {
      margin-right: 0.3em;
    }
    a {
      margin: 0;
      display: flex;
    }
  }

  & .nav-separator-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    font-weight: 100;
    border-bottom: 1px solid;
  }
}

.tags-list {
  .tag-item {
    position: relative;
    font-size: 0.875em;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 0.8em;
    padding-bottom: 0.5em;
    margin-bottom: 0.3em;
    border-bottom: 1px solid;
    cursor: pointer;

    &.disabled {
      cursor: auto;
    }

    &:first-child {
      font-weight: 500;
    }

    &.selected::before {
      content: " ";
      position: absolute;
      top: 0.7em;
      left: -2.3em;
      width: 2em;
      height: 1.125em;
      border-radius: 0 0.3em 0.3em 0;
    }
  }
}

@media only screen and (max-height: 400px) {
  .tags-list {
    margin-top: -1em;
  }
}

@media only screen and (max-width: $medium-device-breakpoint) {
  .plant-details .chart-container {
    margin-left: -1em;
  }
}

.plant-details {
  margin-bottom: 0;
}

.chart-container {
  position: relative;
  height: 40vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 1.65em;

  .chart-loader {
    position: absolute;
    top: 0;
    right: 0;
  }

  .chart-msg {
    font-size: 0.75em;
  }

  .rv-xy-plot {
    touch-action: none;
    .rv-discrete-color-legend {
      text-align: center;
    }
    .rv-xy-plot__axis--vertical {
      .rv-xy-plot__axis__line {
        display: none;
      }
    }
  }

  .rv-discrete-color-legend {
    .rv-discrete-color-legend-item {
      padding-top: 0px;
      padding-bottom: 2px;
    }
  }

  .rv-crosshair {
    z-index: 888;
  }
  .chart--crosshair {
    z-index: 100;
    border-radius: 4px;
    background: #3a3a48;
    color: #fff;

    padding: 7px 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);

    .chart--crosshair-title {
      white-space: nowrap;
      margin-bottom: 0.2rem;
    }
    .chart--crosshair-row {
      white-space: nowrap;
      margin-bottom: 0.2rem;
    }
  }
}

@media only screen and (max-height: 400px) {
  .chart-container {
    margin-bottom: 0px;

    .rv-discrete-color-legend {
      display: none;
    }
  }
}

.dp-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.date-picker {
  .date-picker-btt {
    cursor: pointer;
  }
}
.plant-select-container {
  display: flex;
  flex-direction: row;
  margin-bottom: 1em;
  .plant-select-title {
    display: flex;
    flex-direction: row;
    h2 {
      margin: 0;
    }
    i {
      font-size: 0.75em;
    }
  }
  .plant-select {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    cursor: pointer;
  }
}

.alarm-list {
  display: flex;
  flex-direction: column;

  .alarm-item {
    display: flex;
    flex-direction: column;
    padding-bottom: 0.4em;
    border-bottom: 1px solid;
    margin-bottom: 0.8em;
    margin-top: 0.6em;
    cursor: pointer;
    min-height: 5.625em;

    .alarm-item-meta-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .alarm-item-content {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-top: 0.3em;
      padding-bottom: 0.3em;

      .alarm-item-meta-ack {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        border-left: 1px solid;
        margin-left: 0.7rem;
        width: 2.5rem;
        height: 2.5rem;
        font-size: 1.45em;
      }
      .alarm-item-description {
        font-size: 0.875;
      }
    }

    .alarm-item-header,
    .alarm-item-footer {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      position: relative;

      .alarm-item-meta {
        font-size: 0.75em;
        text-transform: uppercase;
      }

      .alarm-item-date {
        font-size: 0.75em;
        font-weight: 100;
        margin-left: 0.3em;
      }

      .alarm-item-plant-name {
        position: absolute;
        right: 0;
      }
    }
  }
}

.alarm-details {
  .alarm-details-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5em;
    margin-top: 0.5em;

    h2 {
      margin-bottom: 0;
    }
    .alarm-icon {
      font-size: 2.25em;
    }
  }
  .alarm-data-container {
    font-size: 0.875em;
    .data-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
    .data-col {
      display: flex;
      flex-direction: column;
      span:nth-child(1) {
        margin-bottom: 0.4em;
      }
    }

    .data-row,
    .data-col {
      margin-bottom: 0.8em;
      span:nth-child(2) {
        font-weight: 100;
      }
    }

    .btn-wrapper {
      margin-top: 2em;
    }
  }
}

.react-datepicker-wrapper,
.react-datepicker__input-container {
  width: 100%;

  input {
    width: 100%;
  }
}
.react-datepicker__portal {
  background-color: rgba(0, 0, 0, 0.45) !important;
}

.react-datepicker__month-container {
  .react-datepicker__header {
    padding-top: 0.85rem;
    padding-bottom: 1rem;
  }
  .react-datepicker__month {
    .react-datepicker__month-text {
      padding-top: 1rem;
      padding-bottom: 1rem;
    }
  }
}

.react-datepicker-popper[data-placement^="bottom"] {
  margin-top: 14px;
}

.trend-container {
  .btn-wrapper {
    & .btn {
      min-width: auto;
    }
  }
}

.trend-form {
  padding-top: 2em;
  h3 {
    margin-bottom: 1em;
  }
}
.chart-wrapper {
  position: relative;
  margin-top: 2em;

  .secondary-chart {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

@media only screen and (max-width: $medium-device-breakpoint) {
  .chart-wrapper {
    margin-top: 0.5em;
    margin-left: -1em;
    margin-right: -1em;
    max-width: 100%;
  }
}

.chart-legend {
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 0.75em;
  margin-top: 1em;

  .chart-legend-item {
    margin-right: 0.8em;
  }
}

.empty-filter-placeholder-container {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  .empty-filter-placeholder-text {
    max-width: 11em;
    font-weight: 100;
    font-size: 0.75em;
    color: #adadad;
    text-align: right;
    margin-right: 0.5em;
  }
  .img-arrow {
    max-width: 3.5em;
    height: auto;
  }
}

.indicators-legend-container {
  display: flex;
  flex-direction: row;
  margin-bottom: 1.5em;
  justify-content: center;

  .indicators-legend-item {
    display: flex;
    flex-direction: row;
    align-items: center;

    .item-icon {
      width: 0.875em;
      height: 0.875em;
      border-radius: 4px;
      margin-right: 0.33em;
    }

    .item-label {
      font-size: 0.875em;
      margin-right: 1.5em;
    }
  }
}

.text-right {
  justify-content: flex-end !important;
}

.preset-actions {
  margin-top: 1em;
  margin-bottom: 0.7em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .form-msg {
    margin-bottom: 0 !important;
  }

  .btn {
    margin-right: -1em;
  }
}

@media only screen and (max-width: $medium-device-breakpoint) {
  .preset-actions {
    display: block;
    text-align: center;
  }
}

.preset-list {
  margin-bottom: 1.5em;
  .preset-item {
    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 0.8em;
    padding-bottom: 0.5em;
    margin-bottom: 0.3em;
    border-bottom: 1px solid;
    cursor: pointer;

    .preset-item-title {
      font-weight: 500;
      font-size: 1em;
    }

    .preset-item-description {
      margin-top: 0.33em;
      font-size: 0.875em;
    }

    &.selected::before {
      content: " ";
      position: absolute;
      top: 0.7em;
      left: -2.3em;
      width: 2em;
      height: 1.125em;
      border-radius: 0 0.3em 0.3em 0;
    }
  }
}

@media only screen and (max-height: 400px) {
  .tags-list {
    margin-top: -1em;
  }
}

.ReactModalPortal {
  z-index: 9999;
}

.alarm-group {
  margin-bottom: 2em;

  .group-title {
    margin-bottom: 0.5em;
  }
}

.home-alarms {
  flex: 1 1 auto;
  position: relative;
  overflow-y: auto;
}
