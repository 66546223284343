/* ==== Drawer ==== */
.drawer-container {
  position: fixed;
  top: 0;
  z-index: 9999;

  &.hidden {
    display: none;
  }

  &.drawer-lg {
    .drawer-content-wrapper {
      width: 400px;
    }
  }

  .drawer-mask {
    background-color: #000;
    opacity: 0.25;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
  }

  .drawer-header {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    .btt-close {
      font-size: 1.5em;
      display: inline-block;
      text-align: center;
      text-decoration: none;
      border: 0px;
    }
  }

  .drawer-content {
    padding-left: 1em;
    padding-right: 1em;
    flex: 1 1 auto;
    position: relative;
    overflow-y: auto;
    padding-bottom: 2em;
    .btt-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      margin-right: -1em;
    }
  }

  .drawer-content-wrapper {
    width: $drawer-size;
    height: 100%;
    overflow: hidden;
    position: fixed;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    top: 0;

    &.drawer-position-left {
      left: 0;
    }

    &.drawer-position-right {
      right: 0;
    }
  }
}

@media only screen and (max-width: $small-device-breakpoint) {
  .drawer-container {
    &.drawer-lg {
      .drawer-content-wrapper {
        width: 80%;
        max-width: 80%;
      }
    }
  }
}

@media only screen and (min-width: $medium-device-breakpoint) {
  .drawer-container.main-sidebar {
    position: relative;
    z-index: 1;

    .drawer-mask {
      display: none;
    }

    .drawer-content-wrapper {
      position: relative;
    }
  }
}
