$sk-stat-imp-height: 3.2em;
$sk-stat-imp-title-height: 0.875em;
$sk-stat-imp-title-width: 6em;
$sk-stat-imp-title-position: 0px 0px;
$sk-stat-imp-pbar-height: 0.8em;
$sk-stat-imp-pbar-position: 0em $sk-stat-imp-title-height + 0.4em;
$sk-stat-imp-type-size: 0.875em;
$sk-stat-imp-type-position: 0.2em $sk-stat-imp-height - $sk-stat-imp-type-size;
$sk-stat-imp-meta-height: 0.75em;
$sk-stat-imp-meta-width: 25%;

.stat-imp-container {
  &:empty::after {
    content: "";
    display: block;
    width: 100%;
    height: $sk-stat-imp-height;
    margin-bottom: 1.2em;
    background-image: linear-gradient(
        100deg,
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0.5) 50%,
        rgba(255, 255, 255, 0) 80%
      ),
      // title
        linear-gradient(#f0f0f0 $sk-stat-imp-title-height, transparent 0),
      // pbar
        linear-gradient(#f0f0f0 $sk-stat-imp-pbar-height, transparent 0),
      // icon type
        radial-gradient(
          circle $sk-stat-imp-type-size / 2 at center,
          #f0f0f0 99%,
          transparent 0
        ),
      // meta
        linear-gradient(#f0f0f0 $sk-stat-imp-meta-height, transparent 0);

    background-repeat: no-repeat;
    background-size: 50px 200px,
      // title
        $sk-stat-imp-title-width $sk-stat-imp-title-height,
      // pbar
        100% 200px,
      // icon type
        $sk-stat-imp-type-size $sk-stat-imp-type-size,
      // meta
        $sk-stat-imp-meta-width 200px;

    background-position: 0 0,
      // title
        0 0,
      // pbar
        $sk-stat-imp-pbar-position,
      // icon type
        $sk-stat-imp-type-position,
      // meta
        100% $sk-stat-imp-height - $sk-stat-imp-meta-height;

    animation: loading-stat-imp 1s infinite;
  }
}

@keyframes loading-stat-imp {
  to {
    background-position: 100% 0, /* move highlight to right */ 0 0,
      $sk-stat-imp-pbar-position, $sk-stat-imp-type-position,
      100% $sk-stat-imp-height - $sk-stat-imp-meta-height;
  }
}

$sk-prod-ann-label-height: 5em;
$sk-prod-ann-label-width: 2em;
$sk-prod-ann-label-position: 0em 0em;
$sk-prod-ann-pbar-height: 5em;
$sk-prod-ann-bar-position: 2.5em 0em;
.prod-ann-container {
  &:empty::after {
    content: "";
    display: block;
    width: 100%;
    height: 1em;
    margin-bottom: 1.2em;
    background-image: linear-gradient(
        100deg,
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0.5) 50%,
        rgba(255, 255, 255, 0) 80%
      ),
      // label
        linear-gradient(#f0f0f0 $sk-prod-ann-label-height, transparent 0),
      // pbar
        linear-gradient(#f0f0f0 $sk-prod-ann-pbar-height, transparent 0);
    background-repeat: repeat-y;
    background-size: 50px 3em,
      // title
        $sk-prod-ann-label-width $sk-prod-ann-label-height,
      // pbar
        100% $sk-prod-ann-pbar-height;
    background-position: 0 0,
      // title
        $sk-prod-ann-label-position,
      // pbar
        $sk-prod-ann-bar-position;
    animation: loading-prod-ann 1s infinite;
  }
}
@keyframes loading-prod-ann {
  to {
    background-position: 100% 0,
      // title
        $sk-prod-ann-label-position,
      // pbar
        $sk-prod-ann-bar-position;
  }
}

$sk-tags-list-label-height: 1em;
$sk-tags-list-desc-label-width: 8em;
$sk-tags-list-desc-label-position: 0em 0.8em;
$sk-tags-list-value-label-width: 5em;
$sk-tags-list-value-label-position: 100% 0.8em;

.tags-list {
  &:empty::after {
    content: "";
    display: block;
    width: 100%;
    height: 6.5em;
    background-image: linear-gradient(
        100deg,
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0.5) 50%,
        rgba(255, 255, 255, 0) 80%
      ),
      // label description
        linear-gradient(#f0f0f0 $sk-tags-list-label-height, transparent 0),
      // label value
        linear-gradient(#f0f0f0 $sk-tags-list-label-height, transparent 0);
    background-repeat: repeat-y;
    background-size: 50px 3em,
      // label description
        $sk-tags-list-desc-label-width 2em,
      // label value
        $sk-tags-list-value-label-width 2em;
    background-position: 0 0,
      // label description
        $sk-tags-list-desc-label-position,
      // label value
        $sk-tags-list-value-label-position;
    animation: loading-tags-list 1s infinite;
  }
}

@keyframes loading-tags-list {
  to {
    background-position: 100% 0,
      // label description
        $sk-tags-list-desc-label-position,
      // label value
        $sk-tags-list-value-label-position;
  }
}

$sk-alarm-list-sm-label-height: 0.75em;
$sk-alarm-list-label-height: 1em;
$sk-alarm-list-icon-size: 1em;
$sk-alarm-list-label-width: 7em;
$sk-alarm-list-alarmIcon-position: 0em 0em;
$sk-alarm-list-startDate-position: 2em 0.1em;
$sk-alarm-list-description-position: 3em 2em;
$sk-alarm-list-endAlarmIcon-position: 0em 4em;
$sk-alarm-list-endDate-position: 2em 4.1em;
$sk-alarm-list-status-position: 100% 4em;

.alarm-list {
  &:empty::after {
    content: "";
    display: block;
    width: 100%;
    height: 18em;

    background-image: linear-gradient(
        100deg,
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0.5) 50%,
        rgba(255, 255, 255, 0) 80%
      ),
      // alarm icon
        radial-gradient(
          circle $sk-alarm-list-icon-size / 2 at center,
          #f0f0f0 99%,
          transparent 0
        ),
      // start-date label
        linear-gradient(#f0f0f0 $sk-alarm-list-sm-label-height, transparent 0),
      // description
        linear-gradient(#f0f0f0 $sk-alarm-list-label-height, transparent 0),
      // end-alarm icon
        radial-gradient(
          circle $sk-alarm-list-icon-size / 2 at center,
          #f0f0f0 99%,
          transparent 0
        ),
      // end-date label
        linear-gradient(#f0f0f0 $sk-alarm-list-sm-label-height, transparent 0),
      // status label
        linear-gradient(#f0f0f0 $sk-alarm-list-sm-label-height, transparent 0);
    background-repeat: no-repeat;
    background-size: 50px 10em, $sk-alarm-list-icon-size 1em,
      // start-date label
        $sk-alarm-list-label-width 10em,
      // description
        100% 10em,
      // end-alarm icon
        $sk-alarm-list-icon-size 1em,
      // end-date label
        $sk-alarm-list-label-width 10em,
      // status label
        $sk-alarm-list-label-width 10em;
    background-position: 0 0, $sk-alarm-list-alarmIcon-position,
      // start-date label
        $sk-alarm-list-startDate-position,
      // description
        $sk-alarm-list-description-position,
      // end-alarm icon
        $sk-alarm-list-endAlarmIcon-position,
      // end-date label
        $sk-alarm-list-endDate-position,
      // status label
        $sk-alarm-list-status-position;
    animation: loading-alarms-list 1s infinite;
  }
}

@keyframes loading-alarms-list {
  to {
    background-position: 100% 0, $sk-alarm-list-alarmIcon-position,
      // start-date label
        $sk-alarm-list-startDate-position,
      // description
        $sk-alarm-list-description-position,
      // end-alarm icon
        $sk-alarm-list-endAlarmIcon-position,
      // end-date label
        $sk-alarm-list-endDate-position,
      // status label
        $sk-alarm-list-status-position;
  }
}

$sk-home-alarm-list-sm-label-height: 0.75em;
$sk-home-alarm-list-label-height: 1em;
$sk-home-alarm-list-icon-size: 1em;
$sk-home-alarm-list-label-width: 7em;
$sk-home-alarm-list-groupName-position: 0em 0em;
$sk-home-alarm-list-alarmIcon-position: 2em 2em;
$sk-home-alarm-list-startDate-position: 4em 2.1em;
$sk-home-alarm-list-description-position: 5em 4em;
$sk-home-alarm-list-endAlarmIcon-position: 2em 6em;
$sk-home-alarm-list-endDate-position: 4em 6.1em;
$sk-home-alarm-list-status-position: 95% 6em;

.home-alarms {
  &:empty::after {
    content: "";
    display: block;
    width: 94%;
    margin: 0 auto;
    height: 18em;

    background-image: linear-gradient(
        100deg,
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0.5) 50%,
        rgba(255, 255, 255, 0) 80%
      ),
      // group-name label
        linear-gradient(
          #f0f0f0 $sk-home-alarm-list-sm-label-height,
          transparent 0
        ),
      // alarm icon
        radial-gradient(
          circle $sk-home-alarm-list-icon-size / 2 at center,
          #f0f0f0 99%,
          transparent 0
        ),
      // start-date label
        linear-gradient(
          #f0f0f0 $sk-home-alarm-list-sm-label-height,
          transparent 0
        ),
      // description
        linear-gradient(
          #f0f0f0 $sk-home-alarm-list-label-height,
          transparent 0
        ),
      // end-alarm icon
        radial-gradient(
          circle $sk-home-alarm-list-icon-size / 2 at center,
          #f0f0f0 99%,
          transparent 0
        ),
      // end-date label
        linear-gradient(
          #f0f0f0 $sk-home-alarm-list-sm-label-height,
          transparent 0
        ),
      // status label
        linear-gradient(
          #f0f0f0 $sk-home-alarm-list-sm-label-height,
          transparent 0
        );
    background-repeat: no-repeat;
    background-size: 50px 10em,
      // group-name label
        $sk-home-alarm-list-label-width 10em,
      // alarm icon
        $sk-home-alarm-list-icon-size 1em,
      // start-date label
        $sk-home-alarm-list-label-width 10em,
      // description
        88% 10em,
      // end-alarm icon
        $sk-home-alarm-list-icon-size 1em,
      // end-date label
        $sk-home-alarm-list-label-width 10em,
      // status label
        $sk-home-alarm-list-label-width 10em;
    background-position: 0 0,
      // group-name label
        $sk-home-alarm-list-groupName-position,
      // alarm icon
        $sk-home-alarm-list-alarmIcon-position,
      // start-date label
        $sk-home-alarm-list-startDate-position,
      // description
        $sk-home-alarm-list-description-position,
      // end-alarm icon
        $sk-home-alarm-list-endAlarmIcon-position,
      // end-date label
        $sk-home-alarm-list-endDate-position,
      // status label
        $sk-home-alarm-list-status-position;
    animation: loading-home-alarms-list 1s infinite;
  }
}

@keyframes loading-home-alarms-list {
  to {
    background-position: 100% 0,
      // group-name label
        $sk-home-alarm-list-groupName-position,
      // alarm icon
        $sk-home-alarm-list-alarmIcon-position,
      // start-date label
        $sk-home-alarm-list-startDate-position,
      // description
        $sk-home-alarm-list-description-position,
      // end-alarm icon
        $sk-home-alarm-list-endAlarmIcon-position,
      // end-date label
        $sk-home-alarm-list-endDate-position,
      // status label
        $sk-home-alarm-list-status-position;
  }
}

$sk-alarm-details-label-height: 0.875em;
$sk-alarm-details-label-width: 7em;
$sk-alarm-details-value-width: 9em;

$sk-alarm-details-status-label-position: 0em 0em;
$sk-alarm-details-status-value-position: 100% 0em;
$sk-alarm-details-startDate-label-position: 0em 1.8em;
$sk-alarm-details-startDate-value-position: 100% 1.8em;
$sk-alarm-details-endDate-label-position: 0em 3.6em;
$sk-alarm-details-endDate-value-position: 100% 3.6em;
$sk-alarm-details-ackDate-label-position: 0em 5.4em;
$sk-alarm-details-ackDate-value-position: 100% 5.4em;
$sk-alarm-details-area-label-position: 0em 7.2em;
$sk-alarm-details-area-value-position: 100% 7.2em;
$sk-alarm-details-class-label-position: 0em 9em;
$sk-alarm-details-class-value-position: 100% 9em;
$sk-alarm-details-descr-label-position: 0em 10.8em;
$sk-alarm-details-descr-value-position: 0em 12.6em;
$sk-alarm-details-descr2-label-position: 0em 14.4em;
$sk-alarm-details-descr2-value-position: 0em 16.2em;

.alarm-details {
  .alarm-data-container {
    &:empty::after {
      content: "";
      display: block;
      width: 100%;
      height: 20em;
      background-image: linear-gradient(
          100deg,
          rgba(255, 255, 255, 0),
          rgba(255, 255, 255, 0.5) 50%,
          rgba(255, 255, 255, 0) 80%
        ),
        // status label
          linear-gradient(
            #f0f0f0 $sk-alarm-details-label-height,
            transparent 0
          ),
        // status value
          linear-gradient(
            #f0f0f0 $sk-alarm-details-label-height,
            transparent 0
          ),
        // start date label
          linear-gradient(
            #f0f0f0 $sk-alarm-details-label-height,
            transparent 0
          ),
        // start date value
          linear-gradient(
            #f0f0f0 $sk-alarm-details-label-height,
            transparent 0
          ),
        // end date label
          linear-gradient(
            #f0f0f0 $sk-alarm-details-label-height,
            transparent 0
          ),
        // end date value
          linear-gradient(
            #f0f0f0 $sk-alarm-details-label-height,
            transparent 0
          ),
        // ack date label
          linear-gradient(
            #f0f0f0 $sk-alarm-details-label-height,
            transparent 0
          ),
        // ack date value
          linear-gradient(
            #f0f0f0 $sk-alarm-details-label-height,
            transparent 0
          ),
        // area label
          linear-gradient(
            #f0f0f0 $sk-alarm-details-label-height,
            transparent 0
          ),
        // area value
          linear-gradient(
            #f0f0f0 $sk-alarm-details-label-height,
            transparent 0
          ),
        // class label
          linear-gradient(
            #f0f0f0 $sk-alarm-details-label-height,
            transparent 0
          ),
        // class value
          linear-gradient(
            #f0f0f0 $sk-alarm-details-label-height,
            transparent 0
          ),
        // description label
          linear-gradient(
            #f0f0f0 $sk-alarm-details-label-height,
            transparent 0
          ),
        // description value
          linear-gradient(
            #f0f0f0 $sk-alarm-details-label-height,
            transparent 0
          ),
        // description2 label
          linear-gradient(
            #f0f0f0 $sk-alarm-details-label-height,
            transparent 0
          ),
        // description2 value
          linear-gradient(
            #f0f0f0 $sk-alarm-details-label-height,
            transparent 0
          );
      background-repeat: no-repeat;
      background-size: 50px 20em,
        // status label
          $sk-alarm-details-label-width $sk-alarm-details-label-height,
        // status value
          $sk-alarm-details-value-width $sk-alarm-details-label-height,
        // start date label
          $sk-alarm-details-label-width $sk-alarm-details-label-height,
        // start date value
          $sk-alarm-details-value-width $sk-alarm-details-label-height,
        // end date label
          $sk-alarm-details-label-width $sk-alarm-details-label-height,
        // end date value
          $sk-alarm-details-value-width $sk-alarm-details-label-height,
        // ack date label
          $sk-alarm-details-label-width $sk-alarm-details-label-height,
        // ack date value
          $sk-alarm-details-value-width $sk-alarm-details-label-height,
        // area label
          $sk-alarm-details-label-width $sk-alarm-details-label-height,
        // area value
          $sk-alarm-details-value-width $sk-alarm-details-label-height,
        // class label
          $sk-alarm-details-label-width $sk-alarm-details-label-height,
        // class value
          $sk-alarm-details-value-width $sk-alarm-details-label-height,
        // description label
          $sk-alarm-details-label-width $sk-alarm-details-label-height,
        // description value
          100% $sk-alarm-details-label-height,
        // description2 label
          $sk-alarm-details-label-width $sk-alarm-details-label-height,
        // description2 value
          100% $sk-alarm-details-label-height;
      background-position: 0 0,
        // status label
          $sk-alarm-details-status-label-position,
        // status value
          $sk-alarm-details-status-value-position,
        // start date label
          $sk-alarm-details-startDate-label-position,
        // start date value
          $sk-alarm-details-startDate-value-position,
        // end date label
          $sk-alarm-details-endDate-label-position,
        // end date value
          $sk-alarm-details-endDate-value-position,
        // ack date label
          $sk-alarm-details-ackDate-label-position,
        // ack date value
          $sk-alarm-details-ackDate-value-position,
        // area label
          $sk-alarm-details-area-label-position,
        // area value
          $sk-alarm-details-area-value-position,
        // class label
          $sk-alarm-details-class-label-position,
        // class value
          $sk-alarm-details-class-value-position,
        // description label
          $sk-alarm-details-descr-label-position,
        // description value
          $sk-alarm-details-descr-value-position,
        // description2 label
          $sk-alarm-details-descr2-label-position,
        // description2 value
          $sk-alarm-details-descr2-value-position;
      animation: loading-alarm-details-list 1s infinite;
    }
  }
}

@keyframes loading-alarm-details-list {
  to {
    background-position: 100% 0,
      // status label
        $sk-alarm-details-status-label-position,
      // status value
        $sk-alarm-details-status-value-position,
      // start date label
        $sk-alarm-details-startDate-label-position,
      // start date value
        $sk-alarm-details-startDate-value-position,
      // end date label
        $sk-alarm-details-endDate-label-position,
      // end date value
        $sk-alarm-details-endDate-value-position,
      // ack date label
        $sk-alarm-details-ackDate-label-position,
      // ack date value
        $sk-alarm-details-ackDate-value-position,
      // area label
        $sk-alarm-details-area-label-position,
      // area value
        $sk-alarm-details-area-value-position,
      // class label
        $sk-alarm-details-class-label-position,
      // class value
        $sk-alarm-details-class-value-position,
      // description label
        $sk-alarm-details-descr-label-position,
      // description value
        $sk-alarm-details-descr-value-position,
      // description2 label
        $sk-alarm-details-descr2-label-position,
      // description2 value
        $sk-alarm-details-descr2-value-position;
  }
}

$sk-indicators-details-element-height: 1em;
$sk-indicators-details-label-width: 2em;
$sk-indicators-details-label-position: 0.5em 1.5em;
$sk-indicators-details-pbar1-position: 3.5em 0em;
$sk-indicators-details-pbar2-position: 3.5em 1.5em;
$sk-indicators-details-pbar3-position: 3.5em 3em;
.indicators-container {
  &:empty::before {
    content: "";
    display: block;
    width: 100%;
    height: 5em;
    background-image: linear-gradient(
        100deg,
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0.5) 50%,
        rgba(255, 255, 255, 0) 80%
      ),
      // label
        linear-gradient(
          #f0f0f0 $sk-indicators-details-element-height,
          transparent 0
        ),
      // pbar1
        linear-gradient(
          #f0f0f0 $sk-indicators-details-element-height,
          transparent 0
        ),
      // pbar2
        linear-gradient(
          #f0f0f0 $sk-indicators-details-element-height,
          transparent 0
        ),
      // pbar3
        linear-gradient(
          #f0f0f0 $sk-indicators-details-element-height,
          transparent 0
        );

    background-repeat: repeat-y;
    background-size: 50px 3em,
      // label
        $sk-indicators-details-label-width 12em,
      // pbar1
        100% 12em,
      // pbar2
        100% 12em,
      // pbar3
        100% 12em;
    background-position: 0 0,
      // title
        $sk-indicators-details-label-position,
      // pbar1
        $sk-indicators-details-pbar1-position,
      // pbar2
        $sk-indicators-details-pbar2-position,
      // pbar3
        $sk-indicators-details-pbar3-position;
    animation: loading-indicators 1s infinite;
  }
}
@keyframes loading-indicators {
  to {
    background-position: 100% 0,
      // title
        $sk-indicators-details-label-position,
      // pbar1
        $sk-indicators-details-pbar1-position,
      // pbar2
        $sk-indicators-details-pbar2-position,
      // pbar3
        $sk-indicators-details-pbar3-position;
  }
}

$sk-forecasts-details-element-height: 1em;
$sk-forecasts-details-label-width: 2em;
$sk-forecasts-details-label-position: 0.5em 0.75em;
$sk-forecasts-details-pbar1-position: 3.5em 0em;
$sk-forecasts-details-pbar2-position: 3.5em 1.5em;
.forecasts-container {
  &:empty::before {
    content: "";
    display: block;
    width: 100%;
    height: 5em;
    background-image: linear-gradient(
        100deg,
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0.5) 50%,
        rgba(255, 255, 255, 0) 80%
      ),
      // label
        linear-gradient(
          #f0f0f0 $sk-forecasts-details-element-height,
          transparent 0
        ),
      // pbar1
        linear-gradient(
          #f0f0f0 $sk-forecasts-details-element-height,
          transparent 0
        ),
      // pbar2
        linear-gradient(
          #f0f0f0 $sk-forecasts-details-element-height,
          transparent 0
        );

    background-repeat: repeat-y;
    background-size: 50px 3em,
      // label
        $sk-forecasts-details-label-width 12em,
      // pbar1
        100% 12em,
      // pbar2
        100% 12em;
    background-position: 0 0,
      // title
        $sk-forecasts-details-label-position,
      // pbar1
        $sk-forecasts-details-pbar1-position,
      // pbar2
        $sk-forecasts-details-pbar2-position;
    animation: loading-forecasts 1s infinite;
  }
}
@keyframes loading-forecasts {
  to {
    background-position: 100% 0,
      // title
        $sk-forecasts-details-label-position,
      // pbar1
        $sk-forecasts-details-pbar1-position,
      // pbar2
        $sk-forecasts-details-pbar2-position;
  }
}

$sk-preset-list-label-height: 1em;
$sk-preset-list-desc-label-width: 33%;
$sk-preset-list-desc-label-position: 0em 1.3em;
$sk-preset-list-title-label-width: 20%;
$sk-preset-list-title-label-position: 0em 0em;

.preset-list {
  &:empty::after {
    content: "";
    display: block;
    width: 100%;
    height: 4em;
    background-image: linear-gradient(
        100deg,
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0.5) 50%,
        rgba(255, 255, 255, 0) 80%
      ),
      // label description
        linear-gradient(#f0f0f0 $sk-preset-list-label-height, transparent 0),
      // label value
        linear-gradient(#f0f0f0 $sk-preset-list-label-height, transparent 0);
    background-repeat: repeat-y;
    background-size: 50px 3em,
      // label description
        $sk-preset-list-title-label-width 4em,
      // label value
        $sk-preset-list-desc-label-width 4em;
    background-position: 0 0,
      // label description
        $sk-preset-list-title-label-position,
      // label value
        $sk-preset-list-desc-label-position;
    animation: loading-preset-list 1s infinite;
  }
}

@keyframes loading-preset-list {
  to {
    background-position: 100% 0,
      // label description
        $sk-preset-list-title-label-position,
      // label value
        $sk-preset-list-desc-label-position;
  }
}
