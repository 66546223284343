.splashscreen-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  .logo {
    margin-top: -20%;
    .logoText {
      //margin-bottom: 0.8em;
    }
  }
}
