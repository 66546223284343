$primary-color: #396db8; //previous value: #fbde18; #03a9f4
$secondary-color: #d2d0d2;
$foreground-color: #283d72; //previous value: #283d72
$secondary-light-color: #f0f0f0;
$secondary-dark-color: #adadad;

$foreground-header-color: #ffffff; //previous value: #283d72

$error-color: #d82c2b;
$done-color: #71b55e;
$info-color: #90c1db;

$progress-bar-background: $secondary-light-color;
$progress-bar-secondary-background: #fbde18; //previous value: #4cc0f6
$progress-bar-label: #707070;
$progress-bar-primary-color: #90c1db; //previous value: #fbde18
$progress-bar-state-normal: #66bd27; //previous value: #13e855   4caf50
$progress-bar-state-warning: #fbde18;
$progress-bar-state-alarms: #d82c2b;
$progress-bar-disabled: $secondary-color;

$indicator-secondary-color: $secondary-light-color;

$switch-ball-color: #ffffff;
$switch-active-color: $primary-color;
$switch-inactive-color: $secondary-color;
