@font-face {
  font-family: "ste-guardian-icons";
  src: url("../font/ste-guardian-icons.eot?34756696");
  src: url("../font/ste-guardian-icons.eot?34756696#iefix")
      format("embedded-opentype"),
    url("../font/ste-guardian-icons.woff2?34756696") format("woff2"),
    url("../font/ste-guardian-icons.woff?34756696") format("woff"),
    url("../font/ste-guardian-icons.ttf?34756696") format("truetype"),
    url("../font/ste-guardian-icons.svg?34756696#ste-guardian-icons")
      format("svg");
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'ste-guardian-icons';
    src: url('../font/ste-guardian-icons.svg?34756696#ste-guardian-icons') format('svg');
  }
}
*/

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "ste-guardian-icons";
  font-style: normal;
  font-weight: normal;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0.2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: 0.2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-user:before {
  content: "\e800";
} /* '' */
.icon-key:before {
  content: "\e801";
} /* '' */
.icon-clock:before {
  content: "\e802";
} /* '' */
.icon-ok:before {
  content: "\e803";
} /* '' */
.icon-water-drop:before {
  content: "\e804";
} /* '' */
.icon-sun:before {
  content: "\e805";
} /* '' */
.icon-electric:before {
  content: "\e806";
} /* '' */
.icon-breeze:before {
  content: "\e807";
} /* '' */
.icon-gear:before {
  content: "\e808";
} /* '' */
.icon-marker:before {
  content: "\e809";
} /* '' */
.icon-flash:before {
  content: "\e80a";
} /* '' */
.icon-menu:before {
  content: "\e80b";
} /* '' */
.icon-left-arrow:before {
  content: "\e80c";
} /* '' */
.icon-down-open:before {
  content: "\e80d";
} /* '' */
.icon-up-open:before {
  content: "\e80e";
} /* '' */
.icon-left-open-big:before {
  content: "\e80f";
} /* '' */
.icon-right-open-big:before {
  content: "\e810";
} /* '' */
.icon-down-open-big:before {
  content: "\e811";
} /* '' */
.icon-cancel:before {
  content: "\e812";
} /* '' */
.icon-alarm:before {
  content: "\e813";
} /* '' */
.icon-worker:before {
  content: "\e814";
} /* '' */
.icon-calendar:before {
  content: "\e815";
} /* '' */
.icon-download-alt:before {
  content: "\e816";
} /* '' */
.icon-attention:before {
  content: "\e817";
} /* '' */
.icon-chart-c1:before {
  content: "\e818";
} /* '' */
.icon-settings:before {
  content: "\e819";
} /* '' */
.icon-navigation_action:before {
  content: "\e81a";
} /* '' */
.icon-cancel-slim:before {
  content: "\e81b";
} /* '' */
.icon-menu-home:before {
  content: "\e81c";
} /* '' */
.icon-menu-plant:before {
  content: "\e81d";
} /* '' */
.icon-menu-alarms:before {
  content: "\e81e";
} /* '' */
.icon-menu-productions:before {
  content: "\e81f";
} /* '' */
.icon-menu-disponibility:before {
  content: "\e820";
} /* '' */
.icon-menu-performanceRatio:before {
  content: "\e821";
} /* '' */
.icon-menu-indicators:before {
  content: "\e822";
} /* '' */
.icon-menu-monthly:before {
  content: "\e823";
} /* '' */
.icon-ccw:before {
  content: "\e824";
} /* '' */
.icon-menu-changeLng:before {
  content: "\e825";
} /* '' */
.icon-menu-chart:before {
  content: "\e826";
} /* '' */
.icon-menu-trend:before {
  content: "\e827";
} /* '' */
.icon-menu-prodHoursCapFact:before {
  content: "\e828";
} /* '' */
.icon-menu-changePsw:before {
  content: "\e829";
} /* '' */
.icon-menu-logout:before {
  content: "\e82a";
} /* '' */
.icon-menu-shutdowns:before {
  content: "\e82b";
} /* '' */
.icon-menu-forecast:before {
  content: "\e82c";
} /* '' */
.icon-menu-pdm:before {
  content: "\e82d";
} /* '' */
.icon-bookmark:before {
  content: "\e82e";
} /* '' */
.icon-spin:before {
  content: "\e830";
} /* '' */
.icon-filter:before {
  content: "\f0b0";
} /* '' */
.icon-menu-s2:before {
  content: "\f0c9";
} /* '' */
.icon-circle-empty:before {
  content: "\f10c";
} /* '' */
.icon-info:before {
  content: "\f129";
} /* '' */
.icon-attention-alt:before {
  content: "\f12a";
} /* '' */
.icon-dot-circled:before {
  content: "\f192";
} /* '' */
.icon-circle-thin:before {
  content: "\f1db";
} /* '' */
