$mobile-content-margin: 1em;
$desktop-content-margin: 2em;

$main-menu-header-height: 120px;
$drawer-size: 250px;

// Extra small devices (portrait phones, less than 576px) are the default
// landscape phones
$small-device-breakpoint: 576px;
// tablets
$medium-device-breakpoint: 768px;
// desktops
$large-device-breakpoint: 992px;
//large desktops
$extra-large-device-breakpoint: 1200px;
