/* ==== TabNav ==== */
.tab-nav-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;

  .tab-content-wrapper {
    flex: 1 1 auto;
    position: relative;
    overflow-y: auto;
    height: calc(100% - 56px);
  }
  .tab-bar {
    flex: 0 0 auto;
    height: 56px;
    box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.16);
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;

    .tab-item {
      text-decoration: none;
      border: 0;
      background-color: transparent;
      outline: none;
      opacity: 0.5;
      display: flex;
      flex-direction: column;
      align-items: center;

      .tab-icon {
        font-size: 1.75em;
      }

      span {
        display: none;
        font-size: 0.65em;
        text-transform: uppercase;
        margin-top: 0.2em;
      }

      &.active {
        opacity: 1;
        span {
          display: block;
        }
      }
    }
  }
}
