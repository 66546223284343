.offline-banner {
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    bottom: 1%;
    z-index: 1000;
    width: 25%;
    background-color: $error-color;
    text-align: center;
    padding: 5px;
    border-radius: 18px;
    font-size: .75em;
    color: $secondary-light-color;
}