@import "_colors";

$hover-primary-color: darken($primary-color, 7.5%);
$active-primary-color: darken($primary-color, 10%);

body {
  color: $foreground-color;
}

.primary-btn {
  background-color: $primary-color;
  border-color: $primary-color;
  color: $foreground-header-color;

  &:not(:disabled):not(.disabled):hover,
  &:not(:disabled):not(.disabled):focus {
    background-color: $hover-primary-color;
    border-color: darken($hover-primary-color, 1%);
  }

  &:not(:disabled):not(.disabled):active {
    background-color: $active-primary-color;
  }
}

.react-datepicker {
  color: $foreground-color;
  border-color: $hover-primary-color;

  .react-datepicker__triangle {
    border-bottom-color: $primary-color !important;

    &::before {
      border-bottom-color: $hover-primary-color !important;
    }
  }

  .react-datepicker__navigation--previous {
    border-right-color: $foreground-color !important;
  }

  .react-datepicker__navigation--next {
    border-left-color: $foreground-color !important;
  }
}
.react-datepicker__month-container {
  .react-datepicker__header {
    background-color: $primary-color;
    border-bottom-color: $hover-primary-color;
  }
  .react-datepicker__current-month {
    color: $foreground-color;
  }
  .react-datepicker__day-name {
    color: $foreground-color;
  }

  .react-datepicker__day {
    color: $foreground-color;
  }

  .react-datepicker__day--selected,
  .react-datepicker__month--selected,
  .react-datepicker__day--keyboard-selected {
    background-color: $primary-color;
    color: initial;

    &:hover,
    &:focus {
      background-color: $hover-primary-color !important;
    }
  }

  .react-datepicker__day--disabled {
    color: $secondary-color;
  }
}

.form-msg {
  &.type-err {
    color: $error-color;
    border-color: $error-color;
  }

  &.type-done {
    color: $done_color;
    border-color: $done-color;
  }

  &.type-info {
    color: $info_color;
    border-color: $info-color;
  }
}

.pbar {
  background-color: $progress-bar-background;

  & > .pbar-value {
    background-color: $progress-bar-primary-color;
    border-left-color: $progress-bar-primary-color;
  }

  .pbar-lbl {
    color: $progress-bar-label;
  }

  &:nth-child(2) > .pbar-value {
    background-color: $progress-bar-secondary-background;
    border-left-color: $progress-bar-secondary-background;
  }
  &:nth-child(3) > .pbar-value {
    background-color: $secondary-color;
    border-left-color: $secondary-color;
  }
}

.stat-imp-content {
  & > .pbar .pbar-value {
    background-color: $progress-bar-state-normal;
    border-left-color: $progress-bar-state-normal;
  }

  &.imp-alarm > .pbar .pbar-value {
    background-color: $progress-bar-state-alarms !important;
    border-left-color: $progress-bar-state-alarms !important;
  }

  &.imp-wrn > .pbar .pbar-value {
    background-color: $progress-bar-state-warning;
    border-left-color: $progress-bar-state-warning;
  }

  &.imp-no-data {
    color: $progress-bar-disabled;
  }
}

.indicator-container {
  border-bottom-color: $indicator-secondary-color;
}

.main-nav {
  .nav-action {
    .nav-btn {
      color: $secondary-color;
    }
  }
}

.breadcrumb-container {
  .breadcrumb-label {
    background-color: $primary-color;
  }
}

.main-menu-container {
  border-right-color: $secondary-color;
  & .main-menu-header {
    background-color: $primary-color;
  }
  .nav-item.active {
    background-color: $secondary-light-color;
  }
}

.nav {
  & .nav-separator-header {
    color: $secondary-dark-color;
    border-bottom-color: $secondary-color;
  }
}

.tags-list {
  .tag-item {
    border-color: $secondary-light-color;
    /*&.disabled {
      color: $secondary-color;
    }*/
    &.selected::before {
      background-color: $primary-color;
    }
  }
}

.chart-container {
  .chart-loader,
  .chart-msg {
    color: $secondary-color;
  }

  .rv-xy-plot {
    .rv-discrete-color-legend-item {
      color: $foreground-color;
    }
  }
}

.drawer-container {
  .btt-close {
    color: $secondary-color;
  }
}

.alarm-list {
  .alarm-item {
    border-bottom-color: $secondary-color;
  }
}

.plant-select-container {
  .icon-alarm {
    color: $progress-bar-state-alarms;
  }

  .icon-attention {
    color: $progress-bar-state-warning;
  }
}

.indicators-legend-container {
  .indicators-legend-item:first-child > .item-icon {
    background-color: $progress-bar-primary-color;
  }

  .indicators-legend-item:nth-child(2) > .item-icon {
    background-color: $progress-bar-secondary-background;
  }

  .indicators-legend-item:nth-child(3) > .item-icon {
    background-color: $secondary-color;
  }
}

.toolbar {
  .toolbar-meta .meta-group:first-child > .meta-icon {
    background-color: $progress-bar-primary-color;
  }

  .toolbar-meta .meta-group:nth-child(2) > .meta-icon {
    background-color: $progress-bar-secondary-background;
  }

  .toolbar-meta .meta-group:nth-child(3) > .meta-icon {
    background-color: $secondary-color;
  }
}

.preset-list {
  .preset-item {
    border-color: $secondary-light-color;
    &.selected::before {
      background-color: $primary-color;
    }
  }
}
