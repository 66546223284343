$banner-size: 54px;

.aths-container {
  position: fixed;
  left: 0;
  bottom: 0;
  height: $banner-size;
  width: 100vw;
  background-color: white;

  z-index: 999;
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.16);
  display: flex;
  flex-direction: row;
  align-items: center;

  .aths-brand {
    width: $banner-size - 4px;
    height: $banner-size - 4px;
  }

  .aths-content {
    flex-grow: 1;

    .aths-title {
      font-size: 0.875em;
      margin-bottom: 0.33em;
    }

    .aths-info {
      font-size: 0.75em;
    }
  }

  .aths-actions {
    button {
      padding: 0;
      border: 0px;
      height: $banner-size - 4px;
      width: 48px;
      font-size: 0.9em;

      &,
      &:focus,
      &:hover {
        background-color: transparent;
      }
    }
  }
}
