.pbar {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 1em;
  justify-content: flex-start;
  align-items: stretch;
  border-radius: 4px;
  overflow: hidden;
  position: relative;

  &.pbar-sm {
    height: 0.8em;
  }

  .pbar-value {
    border-radius: 4px;
    border-left: 1px solid;
  }

  .pbar-lbl {
    position: absolute;
    right: 0.5em;
    font-size: 0.725em;
    top: 0.275em;
  }
}

.pbar-not-valid {
  font-size: 0.75em;
  padding-right: 0.25em;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 1em;
  justify-content: flex-end;
}
