@import "_variables";

/* ==== Breadcrumb ==== */
.breadcrumb-container {
  font-size: 0.75em;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 0.2em;
  margin-bottom: 0.4em;

  .breadcrumb-label {
    padding: 0.3em 0.8em 0.3em $mobile-content-margin;
    border-radius: 0 0.3em 0.3em 0;
    color: $foreground-header-color;
  }
}
