/* == FORM ITEM == */
.form-item {
  margin-top: 0.5em;
  margin-bottom: 1.5em;

  label {
    margin-bottom: 0.5em;
    display: block;
  }

  .input-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    border-bottom: 1px solid;
    padding: 0em 0.5em 0.33em 0.5em;

    & > div {
      width: 100%;
    }

    input,
    select {
      border: 0px;
      box-shadow: none;
      display: block;
      font-size: inherit;
      font-family: inherit;
      color: inherit;
      background-color: transparent;
      width: 100%;

      &:focus {
        outline: none;
      }

      &::placeholder {
        font-weight: 200;
        color: inherit;
        font-size: 0.85em;
        opacity: 0.5;
      }
    }

    select {
      -webkit-appearance: none;
    }

    textarea {
      display: block;
      width: 100% !important;
      font-size: 1rem;
      line-height: 1.5;
      color: #495057;
      border: 0px;
      box-shadow: none;
      &:focus {
        outline: none;
      }
    }

    i + input,
    i + select {
      margin-left: 0.35em;
    }
  }
}
