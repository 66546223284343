.update-pwa-snackbar {
  visibility: hidden;
  min-width: 250px;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 2px;
  padding: 0.875em;
  position: fixed;
  z-index: 1;
  left: 50%;
  bottom: 1.5em;
  transform: translateX(-50%);
  font-size: 0.875em;

  &.show {
    visibility: visible;
    animation: fadein 0.5s;
  }
}

@-webkit-keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 30px;
    opacity: 1;
  }
}
@keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 30px;
    opacity: 1;
  }
}
